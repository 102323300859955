import React, { ReactNode, FunctionComponent } from 'react';
import { AuthProvider } from './auth-context/auth-provider';
import FinancialCompanyProvider from './BranchContext/FinancialCompanyProvider';
import SnackBar from '../legacy/CapeMorris/components/snack-bar/SnackBar';
import SnackContainer from '../legacy/CapeMorris/components/snack-bar/use-snack-container';
import { LocaleProvider } from './locale-context/locale-provider';
import Modal from '../components/molecules/Modal/Modal';
import ModalContextProvider from './ModalContext/ModalContextProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import OverlayContextProvider from './OverlayContext/OverlayContextProvider';
import OverlayContainer from '../components/molecules/OverlayContainer/OverlayContainer';
import TaskFormProvider from './TaskFormContext/TaskFormProvider';
import TaskFormContainer from './TaskFormContext/TaskFormContainer';
import { SidebarProvider } from './Sidebar/SidebarProvider';
import { IconContext } from 'react-icons';

type Props = {
  children: ReactNode;
};

const queryClient = new QueryClient();

const AllProviders: FunctionComponent<Props> = (props) => {
  return (
    <IconContext.Provider value={{ className: 'react-icons' }}>
      <QueryClientProvider client={queryClient}>
        <LocaleProvider>
          {/*@ts-ignore*/}
          <SnackContainer.Provider>
            <AuthProvider>
              <OverlayContextProvider>
                <SidebarProvider>
                  <ModalContextProvider>
                    <TaskFormProvider>
                      <FinancialCompanyProvider>
                        {props.children}
                        <Modal />
                        <TaskFormContainer />
                        <OverlayContainer />
                      </FinancialCompanyProvider>
                    </TaskFormProvider>
                  </ModalContextProvider>
                </SidebarProvider>
              </OverlayContextProvider>
            </AuthProvider>
            <SnackBar />
          </SnackContainer.Provider>
        </LocaleProvider>
      </QueryClientProvider>
    </IconContext.Provider>
  );
};

export default AllProviders;
