import React, { FunctionComponent, MouseEvent, useContext, useState } from 'react';
import * as Styled from './Resource.styled';
import { ResourceType } from '../../../../@types/Resource/ResourceType';
import ResourceIcon from './ResourceIcon';
import useApi from '../../../../hooks/useApi';
import ImageViewer from 'react-simple-image-viewer';
import DropdownMenu from '../../DropdownMenu/DropdownMenu';
import { ArrowIcon } from '../../../../Icons/arrow.icon';
import { Icon } from '../../../atoms/Icon/Icon';
import { color } from '../../../../styles/Variables';
import { DropdownMenuItemType } from '../../DropdownMenu/DropdownMenuItem/DropdownMenuItem';
import TrashIcon from '../../../../Icons/Trash.icon';
import { ModalContext } from '../../../../Context/ModalContext/ModalContext';
import RenameResourceModal from './RenameResourceModal';
import EditIcon from '../../../../Icons/edit.icon';
import Button from '../../../atoms/Button/Button';
import useShowPdf from '../../../../hooks/ShowPdf/useShowPdf';

type Props = {
  resource: ResourceType;
  onDelete?: (deleted: ResourceType) => void;
  editable?: ((resource: ResourceType) => boolean) | boolean;
};

const Resource: FunctionComponent<Props> = ({ resource, onDelete, editable = true }) => {
  const { showModal, hideModal } = useContext(ModalContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isPreview, setPreview] = useState<boolean>(false);
  const { del } = useApi();
  const canPreview = !!resource.previewUrl;
  const canDownload = !!resource.downloadUrl;
  const preview = (e: MouseEvent) => {
    e.stopPropagation();
    setPreview(true);
  };
  const { showPdf, isPdf } = useShowPdf(resource);

  const canEdit = typeof editable === 'function' ? editable(resource) : editable;

  const handleDelete = () => {
    showModal({
      title: 'Delete resource?',
      width: 42,
      zIndex: 1050,
      body: <>Are you sure you want to delete this resource?</>,
      footer: <Button onClick={confirmDelete} $text={'Delete'} kind={'negative'} $icon={<TrashIcon />} />,
    });
  };

  const confirmDelete = () => {
    del(resource['@id']).then(() => {
      onDelete && onDelete(resource);
      hideModal();
    });
  };
  const handleRename = () => {
    showModal({
      title: 'Rename resource',
      body: (
        <RenameResourceModal
          resource={resource}
          onSave={(r) => {
            resource.name = r.name;
            setMenuOpen(false);
          }}
        />
      ),
      width: 42,
      zIndex: 1050,
    });
  };
  const handleClick = () => {
    if (resource.type === 'mattermost') {
      window.open(resource.url);
    }
    if (resource.type === 'sharepoint') {
      window.open(resource.url, '_blank');
    }
    if (resource.type === 'keynote') {
      window.open(resource.url, '_blank');
    }
    if (resource.type === 'figma') {
      window.open(resource.url, '_blank');
    }
    if (resource.type === 'microsoft_project') {
      window.open(resource.url, '_blank');
    }
    if (resource.type === 'file') {
      if (isPdf) {
        showPdf();
        return;
      }
      if (resource.previewUrl) {
        setPreview(true);
      } else {
        window.open(resource.downloadUrl);
      }
    }
  };
  const menuItems: DropdownMenuItemType[] = [
    {
      text: 'Rename',
      onClick: handleRename,
      icon: <EditIcon />,
    },
    {
      text: 'Delete',
      onClick: handleDelete,
      icon: <TrashIcon />,
    },
  ];
  return (
    <Styled.Container className={'resource-card'} onClick={handleClick} $isNew={resource.new} $canEdit={canEdit}>
      <ResourceIcon resource={resource} />
      <Styled.Content>
        <Styled.Name title={resource.name}>{resource.name}</Styled.Name>
        <Styled.Details>
          {canPreview && <Styled.Action onClick={(e) => preview(e)}>Preview</Styled.Action>}
          {canDownload && (
            <Styled.Action as={'a'} href={resource.downloadUrl} onClick={(e) => e.stopPropagation()}>
              Download
            </Styled.Action>
          )}
          {isPdf && <Styled.Action onClick={() => showPdf()}>Show PDF</Styled.Action>}
          {!canPreview && !canDownload && <p>{resource.url}</p>}
        </Styled.Details>
      </Styled.Content>
      {canEdit && (
        <Styled.Menu
          onClick={(e) => {
            setMenuOpen(!menuOpen);
            e.stopPropagation();
          }}
        >
          <DropdownMenu childrenLeft={true} menuItems={menuItems} triggerType={'click'} onOutsideClick={() => setMenuOpen(false)}>
            <Icon size={1} color={color.primary[60]}>
              <ArrowIcon direction={menuOpen ? 'up' : 'down'} />
            </Icon>
          </DropdownMenu>
        </Styled.Menu>
      )}
      {resource.previewUrl && isPreview && (
        <div onClick={(e) => e.stopPropagation()} style={{ zIndex: 1700 }}>
          <ImageViewer
            closeOnClickOutside={true}
            src={[resource.previewUrl]}
            onClose={() => setPreview(false)}
            backgroundStyle={{ backgroundColor: color.dark }}
          />
        </div>
      )}
    </Styled.Container>
  );
};

export default Resource;
