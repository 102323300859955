import React, { FunctionComponent, useContext, useMemo, useState } from 'react';
import { DictValue } from '../../../../@types/Dictionary/DictValue';
import Form from '../../../molecules/Form/Form';
import { ContractorSubject, ContractorType } from '../../../../@types/Finances/Contractor/ContractorType';
import PageTop from '../../../molecules/Page/PageTop';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import Button from '../../../atoms/Button/Button';
import CardHeading from '../../../molecules/CardHeading/CardHeading';
import { Card } from '../../../atoms/Card/Card';
import PersonBasicDataForm from './components/PersonBasicDataForm';
import CompanyBasicDataForm from './components/CompanyBasicDataForm';
import AddressForm from './components/AddressForm';
import CompanyContactForm from './components/CompanyContactForm';
import PersonContactForm from './components/PersonContactForm';
import BankAccountDataForm from './components/BankAccountDataForm';
import useApi from '../../../../hooks/useApi';
import EditIcon from '../../../../Icons/edit.icon';
import AcceptIcon from '../../../../Icons/Accept.icon';
import { useLocation, useNavigate } from 'react-router-dom';
import BankAccountShow from '../ContractorShow/BankAccountShow';
import { ResourceType } from '../../../../@types/Resource/ResourceType';
import ResourceDropzone from '../../../organisms/Resources/ResourceDropzone';
import SnackContainer from '../../../../legacy/CapeMorris/components/snack-bar/use-snack-container';
import StaticSelectInput from '../../../molecules/Form/SelectInput/StaticSelectInput';
import { SidebarContext } from '../../../../Context/Sidebar/SidebarContext';
import { TextAreaInput } from '../../../molecules/Form/TextAreaInput/TextAreaInput';
import FinanceColumn from '../../../molecules/Finances/FinanceColumn';
import { Helmet } from 'react-helmet';

type Props = {
  contractor?: ContractorType;
  onChange?: () => void;
};

const contractorTypes: DictValue[] = [
  { label: 'Person', value: 'person' },
  { label: 'Company', value: 'company' },
];

const defaults = {
  address: {
    country: 'PL',
  },
  bank: {
    currency: 'PLN',
  },
};

const ContractorForm: FunctionComponent<Props> = ({ contractor, onChange }) => {
  const [loading, setLoading] = useState(false);
  const [uploadedResources, setUploadedResources] = useState<ResourceType[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen, closeSidebar } = useContext(SidebarContext);

  const pageHeading = useMemo(() => {
    if (!contractor) {
      return 'Add new vendor';
    }
    if (contractor.type === 'person') {
      return `#${contractor.id} ${contractor.firstName} ${contractor.lastName}`;
    } else {
      return `#${contractor.id} ${contractor.name}`;
    }
  }, [contractor]);
  const { post, put } = useApi();
  const { showSnack } = SnackContainer.useContainer();

  const handleSubmit = (data: ContractorType) => {
    closeSidebar();
    setLoading(true);
    console.log('morwa data', data);
    data.uploadedResources = uploadedResources.map((resource) => resource.id);
    if (data.outsideEuropeanUnion) {
      data.taxNumber = null;
    }
    if (!contractor) {
      post('/api/contractors', data)
        .then((response) => {
          navigate(location.pathname.replace('create', `${response.data.id}/show`));
          setLoading(false);
          showSnack({
            title: 'Success',
            message: 'Vendor has been added successfully.',
            type: 'success',
          });
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      put(`${contractor['@id']}`, data)
        .then(() => {
          navigate(location.pathname.replace('edit', 'show'));
          setLoading(false);
          showSnack({
            title: 'Success',
            message: 'Vendor has been updated successfully.',
            type: 'success',
          });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const Actions = () => {
    return (
      <Grid spacing={2} $align={'center'}>
        <GridItem $desktop={'auto'}>
          <Button
            $text={loading ? 'Saving...' : 'Save vendor'}
            waiting={loading}
            kind={'primary'}
            type={'submit'}
            $icon={contractor ? <EditIcon /> : <AcceptIcon />}
          />
        </GridItem>
        <GridItem $desktop={'auto'}>
          <Button
            $text={'Cancel'}
            kind={'secondary'}
            onClick={() => {
              navigate(-1);
              closeSidebar();
            }}
          />
        </GridItem>
      </Grid>
    );
  };

  return (
    <>
      <Form onSubmit={(data) => handleSubmit(data)} defaultValues={contractor ?? defaults}>
        {({ watch }) => {
          const type = watch('type');
          return (
            <Grid spacing={4} style={{ marginBottom: '2rem' }}>
              <Helmet>
                <title>{contractor ? 'Edit vendor' : 'Create vendor'} | F.CAPE</title>
              </Helmet>
              <GridItem $desktop={12}>
                <PageTop heading={pageHeading} rightColumn={<Actions />} paddingBottom={'0'} isPdfOpen={isOpen} />
              </GridItem>
              <FinanceColumn>
                <GridItem $desktop={12}>
                  <Card>
                    <CardHeading heading={'Basic info'} />
                    <Grid spacing={4}>
                      <GridItem $desktop={6}>
                        <StaticSelectInput name={'type'} label={'Type'} options={contractorTypes} required />
                      </GridItem>
                      {type === 'person' && <PersonBasicDataForm />}
                      {type === 'company' && <CompanyBasicDataForm />}
                      <GridItem $desktop={12}>
                        <TextAreaInput name={'note'} label={'Comment'} />
                      </GridItem>
                    </Grid>
                  </Card>
                </GridItem>
                {!!type && (
                  <GridItem $desktop={12}>
                    <Card>
                      <CardHeading heading={'Contact'} />
                      <Grid spacing={4}>
                        {type === 'company' && <CompanyContactForm />}
                        {type === 'person' && <PersonContactForm />}
                      </Grid>
                    </Card>
                  </GridItem>
                )}
                {!!type && (
                  <GridItem $desktop={12}>
                    {' '}
                    <Card>
                      <CardHeading heading={'Bank account'} />
                      <Grid spacing={4}>
                        {!contractor && <BankAccountDataForm />}
                        {contractor && <BankAccountShow contractor={contractor} />}
                      </Grid>
                    </Card>
                  </GridItem>
                )}
              </FinanceColumn>
              {isOpen && <GridItem $desktop={12} />}
              <FinanceColumn>
                {!!type && (
                  <GridItem $desktop={12}>
                    <Card>
                      <CardHeading heading={'Documents'} />
                      <Grid spacing={4}>
                        <ResourceDropzone
                          subject={ContractorSubject}
                          subjectId={contractor ? contractor.id : undefined}
                          context={'finances'}
                          onChange={(data) => setUploadedResources(data)}
                        />
                      </Grid>
                    </Card>
                  </GridItem>
                )}
                {!!type && (
                  <GridItem $desktop={12}>
                    <Card>
                      <CardHeading heading={'Address'} />
                      <Grid spacing={4}>
                        <AddressForm />
                      </Grid>
                    </Card>
                  </GridItem>
                )}
              </FinanceColumn>
            </Grid>
          );
        }}
      </Form>
    </>
  );
};

export default ContractorForm;
