import styled, { css } from 'styled-components';
import { color } from '../../../styles/Variables';

export const Sidebar = styled.aside<{
  $visible: boolean;
  $full: boolean;
  $pdfContext?: boolean;
}>`
  z-index: 17;
  position: fixed;
  display: grid;
  grid-template-rows: auto 1fr auto;
  top: 6rem;
  right: 0;
  height: calc(100vh - 6rem);
  min-width: 72rem;

  width: ${({ $pdfContext }) => ($pdfContext ? '50vw' : '82rem')};
  background: ${color.light};
  transition: all 0.2s ease-in-out;
  transform: translateX(100%);
  transform-origin: left;
  overflow: hidden;
  box-shadow: 0 0 1rem ${color.neutral[40]};

  ${(props) => [
    props.$visible &&
      css`
        transform: translateX(0);
        visibility: $visible;
      `,
    !props.$visible &&
      css`
        transform: translateX(100%);
        visibility: hidden;
      `,
    props.$full &&
      css`
        width: 100vw;
      `,
  ]};
`;

export const Body = styled.div`
  position: relative;
  height: 100vh;
`;
