import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import PageTop from '../../../molecules/Page/PageTop';
import Heading from '../../../atoms/Heading';
import Button from '../../../atoms/Button/Button';
import EditIcon from '../../../../Icons/edit.icon';
import { useLocation, useNavigate } from 'react-router-dom';
import { CostSubject, CostType } from '../../../../@types/Finances/Cost/CostType';
import { Card } from '../../../atoms/Card/Card';
import CardHeading from '../../../molecules/CardHeading/CardHeading';
import SingleDetail from '../../../molecules/SingleDetail/SingleDetail';
import RichText from '../../../atoms/RichText/RichText';
import Resources from '../../../organisms/Resources/Resources';
import { parseDate } from '../../../../helpers/date';
import Subcost from './Subcost/Subcost';
import PaymentsCard from '../../../organisms/PaymentsShowCard/PaymentsCard';
import HistoryCard from '../../../molecules/HistoryCard/HistoryCard';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import moneyAsString from '../../../../helpers/moneyAsString';
import FinanceStatus from '../../../molecules/Statuses/FinanceStatus/FinanceStatus';
import { useEntityContext } from '../../../../Context/EntityContext/EntityContext';
import api from '../../../../services/api';
import ApprovalCard from '../../../organisms/RequestCard/ApprovalCard';
import { color } from '../../../../styles/Variables';
import { SidebarContext } from '../../../../Context/Sidebar/SidebarContext';
import DeleteIcon from '../../../../Icons/delete.icon';
import useModal from '../../../../hooks/useModal';
import PurchaseOrderSingleDetail from '../../../molecules/SingleDetail/PurchaseOrderSingleDetail/PurchaseOrderSingleDetail';
import useApi from '../../../../hooks/useApi';
import ContractorSingleDetail from '../../../molecules/SingleDetail/ContractorSingleDetail/ContractorSingleDetail';
import { Colored } from '../../../../legacy/CapeMorris/components/layout/layout.styled';
import BetterChip from '../../../atoms/Chips/BetterChip';
import { PiBankBold } from 'react-icons/pi';
import DateString from '../../../atoms/DateString/DateString';
import Text from '../../../atoms/Text';
import { createUrl } from '../../../organisms/Table/Table';
import { ArrayResponseType } from '../../../../@types/hydra/hydra';
import ArrowLongIcon from '../../../../Icons/ArrowLong.icon';
import FinanceColumn from '../../../molecules/Finances/FinanceColumn';
import { Helmet } from 'react-helmet';

type Props = {
  cost: CostType;
  inbox?: boolean;
};

const CostShow: FunctionComponent<Props> = ({ cost, inbox = false }) => {
  const ctx = useEntityContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen, closeSidebar } = useContext(SidebarContext);
  const { showModal, hideModal } = useModal();
  const { post } = useApi();
  const [nextCost, setNextCost] = useState<number | undefined>();
  const [prevCost, setPrevCost] = useState<number | undefined>();
  const { can } = React.useContext(AuthContext);

  const cloneCost = useCallback(() => {
    const clonedCost = { ...cost, id: undefined };
    navigate('/finances/costs/costs/create', {
      state: { cloned: clonedCost },
    });
  }, [cost]);

  const handleReopen = () => {
    closeSidebar();
    return post<CostType>(`/costs/${cost.id}/reopen`, {}).then((response) => {
      ctx.replace(response.data);
    });
  };

  const deleteCost = useCallback(() => {
    return api.delete(`/costs/${cost.id}`).then(() => {
      hideModal();
      navigate('/finances/costs/costs');
    });
  }, []);

  const handleDelete = useCallback(() => {
    showModal({
      title: `Delete cost '${cost.number}'?`,
      body: <span>Are you sure you want to delete this cost?</span>,
      footer: (
        <>
          <Button $text={'Cancel'} type={'button'} kind={'ghost'} onClick={hideModal} />
          <Button $text={'Delete'} type={'button'} kind={'negative'} $icon={<DeleteIcon />} onClick={deleteCost} />
        </>
      ),
    });
  }, []);

  const prepareUrl = () => {
    const context = inbox ? '/api/costs-inbox|CostsInbox' : '/api/costs|Costs';

    const localStorageValue = sessionStorage.getItem(`TABLE::${context}`);
    const json = localStorageValue ? JSON.parse(localStorageValue) : {};
    delete json.page;
    json.count = 1;
    return createUrl(inbox ? '/api/costs-inbox' : '/api/costs', json, {});
  };

  const loadNextCost = () => {
    const url = prepareUrl();
    api
      .get<ArrayResponseType<CostType>>(url, {
        params: {
          'id[gt]': cost.id,
          'order[id]': 'asc',
        },
      })
      .then(({ data }) => {
        const costs = data['hydra:member'];
        if (costs.length) {
          setNextCost(costs[0].id);
        } else {
          setNextCost(undefined);
        }
      });
  };

  const loadPrevCost = () => {
    const url = prepareUrl();
    api
      .get<ArrayResponseType<CostType>>(url, {
        params: {
          'id[lt]': cost.id,
        },
      })
      .then(({ data }) => {
        const costs = data['hydra:member'];
        if (costs.length) {
          setPrevCost(costs[0].id);
        } else {
          setPrevCost(undefined);
        }
      });
  };

  useEffect(() => {
    loadNextCost();
    loadPrevCost();
  }, [cost]);

  const PageActions = () => {
    return (
      <Grid spacing={2} $align={'center'}>
        <Helmet>
          <title>{`Cost ${cost.number} | F.CAPE`}</title>
        </Helmet>
        <GridItem $desktop={'auto'}>
          <HistoryCard subject={CostSubject} subjectId={cost.id} itemCreatedAt={cost.createdAt} itemCreatedBy={cost.createdBy} />
        </GridItem>
        {can('FINANCES.COSTS.CAN_MANAGE') && cost.status !== 'accepted' && (
          <GridItem $desktop={'auto'}>
            <Button $text={'Edit'} kind={'primary'} type={'submit'} $icon={<EditIcon />} onClick={() => navigate(`/finances/costs/costs/${cost.id}/edit`)} />
          </GridItem>
        )}
        {cost.status === 'accepted' && can('FINANCES.COSTS.CAN_MANAGE') && (
          <GridItem $desktop={'auto'}>
            <Button $text={'Reopen'} kind={'ghost'} onClick={handleReopen} />
          </GridItem>
        )}
        {can('FINANCES.COSTS.CAN_MANAGE') && (
          <GridItem $desktop={'auto'}>
            <Button $text={'Clone'} kind={'ghost'} onClick={() => cloneCost()} />
          </GridItem>
        )}
        {nextCost && (
          <GridItem $desktop={'auto'}>
            <Button
              onClick={() =>
                navigate(location.pathname.replace(`${cost.id}`, `${nextCost}`), {
                  replace: true,
                })
              }
              $text={''}
              kind={'secondary'}
              $icon={<ArrowLongIcon rotate={180} arrowColor={'white'} />}
            />
          </GridItem>
        )}
        {prevCost && (
          <GridItem $desktop={'auto'}>
            <Button
              onClick={() =>
                navigate(location.pathname.replace(`${cost.id}`, `${prevCost}`), {
                  replace: true,
                })
              }
              $text={''}
              kind={'secondary'}
              $icon={<ArrowLongIcon arrowColor={'white'} />}
            />
          </GridItem>
        )}
      </Grid>
    );
  };

  const handleRequestApproval = useCallback(() => {
    return post<CostType>(`/costs/${cost.id}/request-approval`, {}).then((response) => {
      ctx.replace(response.data);
    });
  }, [cost, navigate]);

  const parseBankNumber = (number: string) => {
    // remove spaces and add spaces in bank account format. after 2 first digits and then after every 4 digits
    return number
      .replace(/\s/g, '')
      .replace(/^(\d{2})(?=\d)/g, '$1 ')
      .replace(/([\dA-Z]{4})(?=\d)/g, '$1 ')
      .trim();
  };

  const bankNumber = cost.contractor?.bank?.number ? parseBankNumber(cost.contractor.bank.number) : undefined;

  return (
    <>
      <Grid spacing={4} style={{ marginBottom: '2rem' }}>
        <PageTop
          heading={
            <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
                <Heading>
                  Cost&nbsp;
                  <Colored>#{cost.number}</Colored>
                  &nbsp;{cost.contractor ? `(${cost.contractor.name})` : <Colored color={color.neutral[50]}>(No vendor provided)</Colored>}
                </Heading>
              </div>
              <FinanceStatus status={cost.status} />
              {cost.internal && <BetterChip>Internal</BetterChip>}
              {cost.confidential && <BetterChip>Confidential</BetterChip>}
              {cost.bankExportEnabled && (
                <BetterChip color={'information'} icon={PiBankBold}>
                  Bank export enabled
                </BetterChip>
              )}
            </div>
          }
          rightColumn={<PageActions />}
          paddingBottom={'0'}
          isPdfOpen={isOpen}
          showBackButton
        />
        <FinanceColumn>
          <GridItem $desktop={12}>
            <Card>
              <CardHeading heading={'Cost information'} />
              <Grid spacing={4}>
                <GridItem $desktop={12}></GridItem>
                <SingleDetail title={'Document number'} $desktop={4}>
                  {cost.number}
                </SingleDetail>
                <SingleDetail title={'Company name'} $desktop={4}>
                  {cost.financialCompany && cost.financialCompany.name}
                </SingleDetail>
                <ContractorSingleDetail contractor={cost.contractor} $desktop={5} />
                <SingleDetail title={'Bank account'} $desktop={7}>
                  {cost.contractor?.bank?.number ? (
                    <>
                      <Text style={{ marginBottom: '0.2rem', marginTop: '-1.05rem' }} size={'l'}>
                        {bankNumber?.substring(0, bankNumber?.length - 4)}
                        <Text
                          bold
                          style={{
                            display: 'inline-block',
                            fontSize: '1.8em',
                          }}
                        >
                          {bankNumber?.substring(bankNumber?.length - 4)}
                        </Text>
                      </Text>
                      <FinanceStatus status={cost.contractor.status} />
                      <Text style={{ marginLeft: '0.5rem', display: 'inline-block' }} color={'grey'} size={'xs'}>
                        by {cost.contractor.lastSigner?.name} {cost.contractor.signedAt ? <DateString input={cost.contractor.signedAt} time /> : undefined}
                      </Text>
                    </>
                  ) : (
                    'N/A'
                  )}
                </SingleDetail>
                {cost.purchaseOrder && <PurchaseOrderSingleDetail purchaseOrder={cost.purchaseOrder} $desktop={6} />}
                <GridItem $desktop={12} style={{ borderBottom: `1px solid ${color.neutral[40]}` }}></GridItem>
                <SingleDetail title={'Issued at'} $desktop={4} type={'date'}>
                  {parseDate(cost.issuedAt)}
                </SingleDetail>
                <SingleDetail title={'Posted at'} $desktop={4} type={'date'}>
                  {parseDate(cost.postedAt)}
                </SingleDetail>
                <SingleDetail title={'Payment date'} $desktop={4} type={'date'}>
                  {parseDate(cost.paymentDue)}
                </SingleDetail>
                <SingleDetail title={'Net value'} $desktop={4} type={'money'}>
                  {moneyAsString(cost.amountNet)}
                </SingleDetail>
                <SingleDetail title={'Gross value'} $desktop={4} type={'money'}>
                  {moneyAsString(cost.amountGross)}
                </SingleDetail>
                {cost.amountNet.currency !== cost.originalAmountNet.currency && (
                  <>
                    <SingleDetail title={'Exchange rate'} $desktop={4}>
                      {cost.exchangeRate}
                    </SingleDetail>
                    <SingleDetail title={'Original Net value'} $desktop={4} type={'money'}>
                      {moneyAsString(cost.originalAmountNet)}
                    </SingleDetail>
                    <SingleDetail title={'Original Gross value'} $desktop={4} type={'money'}>
                      {moneyAsString(cost.originalAmountGross)}
                    </SingleDetail>
                  </>
                )}
                <GridItem $desktop={12} style={{ borderBottom: `1px solid ${color.neutral[40]}` }}></GridItem>
                {cost.purchaseOrder && (
                  <SingleDetail title={'Amount net (PO)'} $desktop={4} type={'money'}>
                    {cost.purchaseOrder.originalAmountNet.asString}
                  </SingleDetail>
                )}
                {cost.purchaseOrder && (
                  <SingleDetail title={'Remain to invoice (PO)'} $desktop={4} type={'money'}>
                    {cost.purchaseOrder.remainNet ? cost.purchaseOrder.remainNet.asString : 'N/A'}
                  </SingleDetail>
                )}
                {cost.purchaseOrder && (
                  <SingleDetail title={'Payment terms (PO)'} $desktop={4} type={'money'}>
                    {cost.purchaseOrder.paymentTerms}
                  </SingleDetail>
                )}
                {cost.purchaseOrder && cost.purchaseOrder.specialPaymentTermsDetails && (
                  <SingleDetail title={'Special Payment Terms Details (PO)'} $desktop={12}>
                    {cost.purchaseOrder.specialPaymentTermsDetails}
                  </SingleDetail>
                )}
                {cost.purchaseOrder && cost.purchaseOrder.specialPaymentTermsDetails && (
                  <SingleDetail title={'Special Payment Terms Reason (PO)'} $desktop={12}>
                    {cost.purchaseOrder.specialPaymentTermsDetails}
                  </SingleDetail>
                )}
                {cost.purchaseOrder && <GridItem $desktop={12} style={{ borderBottom: `1px solid ${color.neutral[40]}` }}></GridItem>}
                <SingleDetail title={'Note'} $desktop={12}>
                  <RichText content={cost.note ?? 'N/A'} />
                </SingleDetail>
                <SingleDetail title={'Split payment'} $desktop={6}>
                  {cost.splitPayment ? 'Yes' : 'No'}
                </SingleDetail>
                <SingleDetail title={'Excl. from bank export'} $desktop={6}>
                  {cost.excludeFromBankExport ? 'Yes' : 'No'}
                </SingleDetail>
              </Grid>
            </Card>
          </GridItem>

          <GridItem $desktop={12}>
            <PaymentsCard subject={cost} currency={cost.currency} payments={cost.payments} amountGross={cost.originalAmountGross} />
          </GridItem>
        </FinanceColumn>
        {isOpen && <GridItem $desktop={12} />}
        <FinanceColumn>
          <GridItem $desktop={12} style={{ order: isOpen ? 2 : 0 }}>
            <Card>
              <CardHeading heading={'Uploaded documents'} />
              <Grid spacing={4}>
                <Resources subject={CostSubject} subjectId={cost.id} disableCreation maxWidth noResourcesText={'No documents'} />
              </Grid>
            </Card>
          </GridItem>
          {(cost.status === 'draft' || cost.status === 'automated_draft') && (
            <GridItem $desktop={12}>
              <ApprovalCard requestable={cost} requests={[]} onApprovalRequest={handleRequestApproval} />
            </GridItem>
          )}

          <GridItem $desktop={12}>
            <Grid spacing={4}>
              {cost.subcosts.map((subcost, index) => (
                <GridItem $desktop={12} key={subcost['@id']}>
                  <Subcost subcost={subcost} index={index} />
                </GridItem>
              ))}
            </Grid>
          </GridItem>
        </FinanceColumn>
      </Grid>
      {can('FINANCES.CAN_DELETE') && (
        <Grid style={{ margin: '2rem 0 6rem' }}>
          <GridItem $desktop={'auto'}>
            <Button $text={'Delete'} kind={'link'} type={'button'} onClick={handleDelete} style={{ padding: 0, color: color.semantic.error[100] }} />
          </GridItem>
        </Grid>
      )}
    </>
  );
};

export default CostShow;
