import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/styles/fonts.css';
import './assets/styles/ckeditor.css';
import GlobalStyle from './styles/GlobalStyle';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>,
);

// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
export { TextInput } from './components/molecules/Form/TextInput/TextInput';
